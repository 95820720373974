.fade-enter {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0.01;
    overflow:hidden;
}
.fade-enter.fade-enter-active {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 1;
    will-change: opacity;
    transition: opacity 150ms ease-in;
}
.fade-exit {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 1;
}
.fade-exit.fade-exit-active {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0.01;
    will-change: opacity;
    overflow:hidden;
    transition: opacity 150ms ease-in;
}